// Customizable Area Start
import React from "react";
import {
  Box,
  Typography,
  styled,
  Grid,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Switch,
  Button,
  TextField,
  Snackbar,
  BoxProps,
  Popover,
  Chip,
} from "@mui/material";

import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css'; 
import './calendarStyles.css';
import {air1,air2,air3,calender2,avatar,group_image,calendar1,plusIcon,left,right,calendar2, unCheckedBox, checkedBox, upArrow, downArrow, greenTick, cross, leftArrow, filter, airplane, take_off_airplane,button_edit} from './assets'
import BigCalender from './BigCalender.web'

import SchedulingController, {
  Aircraft,
  Props,
} from "./SchedulingController";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { View, TouchableOpacity, StyleSheet, Image } from "react-native";
import CloseIcon from "@mui/icons-material/Close";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import EastIcon from '@mui/icons-material/East';
import PublicIcon from '@mui/icons-material/Public';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import AirplaneTicketOutlinedIcon from '@mui/icons-material/AirplaneTicketOutlined';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import { Calendar as Bcalendar } from "@builder/component-library";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import TodayOutlinedIcon from '@mui/icons-material/TodayOutlined';

interface CustomBoxSnackbarProps extends BoxProps {
  alertType?: 'success' | 'error';
}

export default class SchedulingAvailability extends SchedulingController {
  constructor(props: Props) {
    super(props);
  }
  handleDateChange = (selectedDate:any) => {
    this.setState({ date: selectedDate });
  };

  renderNotifyMembers() {
    const { allMembers } = this.state;
    
    return allMembers.map((item, index) => {
      const initials = item.name
        .split(" ")
        .map((n: any[]) => n[0])
        .join("");
  
      return (
        <TouchableOpacity key={index} style={styles.allCheck} data-test-id={`toggleSelectMember-${index}`}  onPress={() => this.handleSelectMembers(item)}>
          <Image source={this.handleMemberCheckbox(item.id)
                  ? checkedBox
                  : unCheckedBox} style={styles.imageStyle} />
          {item.image ? (
            <Image source={{ uri: item.image }} style={styles.imageProfileStyle} />
          ) : (
            <View style={[styles.initialsContainer, styles.imageProfileStyle]}>
              <Typography style={styles.initialsText}>{initials}</Typography>
            </View>
          )}
          <View>
            <Typography style={styles.memberName}>{item.name}</Typography>
          </View>
        </TouchableOpacity>
      );
    });
  }
  

  renderSelectNotifyPeoples() {
    const { isNotifyContainerOpen, userSelectedMembers} = this.state;
    if(this.state.shouldNotifyAlluser) return
    return (
      <View style={styles.borderBottom}>
        <TouchableOpacity
          data-test-id="toggleNotifyUser"
          onPress={() => this.toggleNotifyUserContainer()}
          style={[styles.subContainer2,{marginBottom :10}]}
        >
          <View style={styles.flexCenter}>
            <GroupsOutlinedIcon style={webStyle.icons}/>
            <Typography
              style={{
                  fontSize: 16,
                  fontWeight: "400",
                  marginLeft:25,
                  color: this.getColorForNotifyUser()
              }}
            >
              {!isNotifyContainerOpen && userSelectedMembers && userSelectedMembers.length > 0
                    ? this.formatSelectedMembers(userSelectedMembers)
                    : "Select people to notify"}
            </Typography>
          </View>
          <View>
            <Image
              style={{
              height: 12,
              width: 12,
              resizeMode: "contain",
              tintColor: "#94A3B8",
              }}
              source={this.state.isNotifyContainerOpen ? upArrow : downArrow}
            />
          </View>
        </TouchableOpacity>
        {isNotifyContainerOpen && (
          <View style={{ paddingVertical: 10, paddingHorizontal:5}}>
            <Box sx={webStyle.searchContainer}>
                <SearchOutlinedIcon style={webStyle.icons} />
              <CustomSearchField
                style={webStyle.searchPlaceholder}
                placeholder="Search waitlist members"
              />
            </Box>
            <TouchableOpacity
              style={styles.allCheck}
              data-test-id = "select-all-members"
              onPress={() => this.toggleSelectAllMembers()}
            >
              <Image
                source={this.handleAllCheckIconforMembers()}
                style={styles.imageStyle}
              />
              <Typography 
              style={styles.selectText}>
                Select the first 10 waitlist members
              </Typography>
            </TouchableOpacity>
            {this.renderNotifyMembers()}
          </View>
        )}
      </View>
    );
  }

  renderCalender(){
    const { isCalenderOpen, availabilityDate ,todayDate} = this.state
    return <>
      <View style={styles.calenderContainer}>
          <TouchableOpacity
            data-test-id="open-calender-btn"
            onPress={() => this.toggleCalenderOpen()}
          >
            <View
              style={styles.subContainer2}
            >
              <View style={{ flexDirection: "row" }}>
                <TodayOutlinedIcon style={webStyle.icons}/>
                <View style={{ marginLeft: 25 }}>
                  <Typography
                    style={{
                      fontSize: 16,
                      color: isCalenderOpen ? "#0074BF" : "#0F172A",
                      fontWeight: "500",
                    }}
                  >
                    {this.formatDate(new Date(availabilityDate))}
                  </Typography>
                </View>
              </View>
              <Box sx={{cursor: "pointer"}}>
              <Image
                style={styles.arrowStyle}
                source={isCalenderOpen ? upArrow : downArrow}
              />
              </Box>
              
            </View>
          </TouchableOpacity>
        </View>
        {isCalenderOpen && (
            <CustomBox >
              <Bcalendar
                currentDate={new Date()}
                test-test-id="calender-basic"
                style={{maxWidth:"100%"}}
                minDate={new Date().toISOString().split("T")[0]}
                onDayPress={(day: { dateString: any }) => {
                  this.setState({
                    availabilityDate: day.dateString,
                    isCalenderOpen: false, 
                    todayDate: ""
                  });
                }}
                
                markedDates={{
                  [availabilityDate]: {
                    selected: true,
                    disableTouchEvent: true,
                    selectedColor: "#5274B5",
                    selectedTextColor: "white",
                  },
                  [todayDate]: {
                    selected: true,
                    selectedColor: todayDate==="" ? "#fff" :"#5274B5",
                    selectedTextColor: "white",
                    disableTouchEvent: true,
                  },
                }}
                theme={{
                  selectedDayBackgroundColor: "#5274B5",
                  selectedDayTextColor: "white",
                  dayTextColor: "#64748B",
                }}
              />
            </CustomBox>
          )}
    </>
  }

  renderCalenderView() {
    return(
      <View style={{ height: "inherit" }}>
        <View
          style={styles.detailsContainer}
        >
          <Typography style={styles.detailsText}>
            TIMING
          </Typography>
        </View>
        {this.renderCalender()}
      </View>
    )
  }

  renderSingleTimeSlot(time:any, index: number){
    return <>
      <View
        key={index}
        data-test-id="dateContainer"
        style={styles.timeSlots}
      >
        <View
          style={styles.subContainer}
        >
          <View style={styles.timePickerContainer}>
            {index < 1 || this.state.isEditAvailabilityOpen ? (
              <Box data-test-id={`timePicker${index}`} onClick={() => { this.timePickerInputRefs[`starttime_${index}`]?.click()}}>
                <AccessTimeIcon style={webStyle.icons}/>
              </Box>
            ) : (
              <Box data-test-id="delete-time-slot" onClick={() => this.handleDelete(index)}>
                <DeleteOutlineIcon style={styles.deleteIcon} />
              </Box>
            )}
            <Box style={{marginLeft:"10px"}}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <MobileTimePicker
                  data-test-id="txtInputAvailableToStartTime"
                  ampm={false}
                  value={moment(time.starttime ?? "10:00", "HH:mm").toDate()}
                  onChange={(date) => {
                    if (date) {
                      this.handleUpdateTimeSlot(index, "starttime", date as any);
                    }
                  }}
                  renderInput={(params) => (
                    <CustomTextField
                      {...params}
                      sx={{ cursor: "pointer" }}
                      inputRef={(input:any) => {
                        this.timePickerInputRefs[`starttime_${index}`] = input;
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            </Box>
            <Box sx={{cursor: "pointer"}}
              data-test-id={`open-time-picker-starttime${index}`}
              onClick={() => {
                this.timePickerInputRefs[`starttime_${index}`]?.click();
              }}
              style={styles.flexContainer}
            >
              <Image
                style={styles.arrowStyle}
                source={downArrow}
              />
            </Box>
          </View>

          <Box><EastIcon style={styles.eastIcon} /></Box>
          <View style={styles.timePickerContainer}>
            <Box data-test-id={`time-picker-endtime${index}`}
              onClick={() => {
                this.timePickerInputRefs[`endtime_${index}`]?.click();
              }}>
              <AccessTimeIcon style={webStyle.icons}/>
            </Box>
            <Box style={{marginLeft:"10px", cursor:"pointer"}}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <MobileTimePicker
                data-test-id="txtInputAvailableToEndTime"
                ampm={false}
                value={moment(time.endtime ?? "11:00", "HH:mm").toDate()}
                onChange={(date) => {
                    this.handleUpdateTimeSlot(index, "endtime", date as any);
                }}
                renderInput={(params) =>  <CustomTextField
                  {...params}
                  sx={{ cursor: "pointer" }}
                  inputRef={(input: any) => {
                    this.timePickerInputRefs[`endtime_${index}`] = input;
                  }}
                />}
              />
            </LocalizationProvider>
            </Box>
            <Box onClick={() => { this.timePickerInputRefs[`endtime_${index}`]?.click()}}
              style={styles.flexContainer}
              data-test-id={`open-time-picker-endtime${index}`}
            >
              <Box sx={{cursor: "pointer"}}>
                <Image
                  style={styles.arrowStyle}
                  source={downArrow}
                />
              </Box>
            </Box>
          </View>
        </View>
      </View>
    </>
  }

  renderTimeSlots() {
    const { timeSlot } = this.state
    if(this.state.timeSlot.length <= 0) return
    return(timeSlot.map((time, index) => (
      this.renderSingleTimeSlot(time, index)
    )))
  }

  renderTimezone() {
    const { showtimeZoneOverlay, timeZones, selectedTimeZone} = this.state
    return(         
      <View
        data-test-id="dateContainer"
        style={styles.dateContainer}
      >
        <TouchableOpacity
          data-test-id="opentimeZoneoverlay"
          onPress={() => this.toggleShowtimeZoneOverlay()}>
          <View
            style={styles.subContainer2}
          >
            <View style={{ flexDirection: "row", alignContent: "center" }}>
              <PublicIcon style={webStyle.icons} />
              <View style={{ marginLeft: 25 }}>
                <Typography
                  style={{
                    fontSize: 16,
                    color: this.state.showtimeZoneOverlay
                      ? "#0074BF"
                      : "#0F172A",
                    fontWeight: "500",
                  }}
                >
                  {this.state.selectedTimeZone}
                </Typography>
              </View>
            </View>
            <Box sx={{cursor: "pointer"}}><Image
              style={styles.arrowStyle}
              source={this.state.showtimeZoneOverlay ? upArrow : downArrow} /></Box>
            
          </View>
        </TouchableOpacity>
      {showtimeZoneOverlay
        ? timeZones.map((zone, index) => (
          <View
            key={index}
            style={{
              width: "100%",
              height: 50,
              backgroundColor:
                selectedTimeZone == zone
                  ? "#E0F2F8"
                  : "#fff",
              justifyContent: "center",
            }}
          >
            <TouchableOpacity key={index}>
              <Typography data-test-id="selecttimeZone" style={styles.selectedZone} onClick={()=>this.handleSelectTimezone(zone,index)}>
                {zone}
              </Typography>
            </TouchableOpacity>
          </View>
        ))
        : null}
      </View>
  )}

  renderTimezoneOverlay() {
    return (
      <>
        {this.renderTimezone()}
        <View style={styles.subContainer2}>
        <StyledButtonContainer>
            <CustomButton
              data-test-id="cancel"
              variant="outlined"
              className="cancelButton"
              onClick={() => this.handleCancel()}>
                Cancel
            </CustomButton>
          </StyledButtonContainer>
          <StyledButtonContainer>
            <CustomButton
              data-test-id="set-details"
              variant="contained"
              color="primary"
              className="setDetailsButton"
              onClick={() => {this.setState({ isSetDetailsOpen: true, open: false })}}>
                Set details
            </CustomButton>
          </StyledButtonContainer>
        </View>
      </>
    )
  }

  renderSelectAirportView() {
    const { isSelectAirportOpen, allAirports, userSelectedAirports} = this.state
    return(
      <View style={styles.borderBottom}>
      <TouchableOpacity
        data-test-id="select-airport"
        onPress={() => this.toggleSelectAirport()}
      >
        <View style={styles.subContainer2}>
          <View style={styles.flexCenter}>
            <FlightTakeoffIcon style={webStyle.icons} />
            <View style={{ marginLeft: 25 }}>
              <Typography
                style={{
                  fontSize: 16,
                  color: this.getColor(),
                  fontWeight: "500",
                }}
              >
                {!isSelectAirportOpen && userSelectedAirports && userSelectedAirports.length > 0
                  ? this.formatSelectedAirports(userSelectedAirports)
                  : "Select airport(s)"}
              </Typography>
            </View>
          </View>
          <Box sx={{cursor: "pointer"}}>
          <Image
            style={styles.arrowStyle}
            source={isSelectAirportOpen ? upArrow : downArrow}
          />
          </Box>
         
        </View>
      </TouchableOpacity>
      {isSelectAirportOpen && <View style={styles.selectAirport}>
        <TouchableOpacity
          data-test-id="selectAllAirports"
          onPress={() => this.toggleSelectAllAirports()}
          style={styles.selectAllAirport}
        >
          <Image
          source={this.handleAllCheckIcon()}
          style={styles.allCheckImage}
        />
          <Typography style={styles.airportText}>All airports</Typography>
        </TouchableOpacity>
        {allAirports.length >= 1 &&
          allAirports.map((item, index) => (
            <TouchableOpacity
              data-test-id={`selectSingleAirport-${index}`}
              onPress={() => this.handleSelectAirport(item)}
              key={item.service_subcategory_id}
              style={styles.selectAllAirport}
            >
              <Image
              source={
                this.handleAirportCheckbox(item.service_subcategory_id)
                  ? checkedBox
                  : unCheckedBox
              }
              style={styles.checked}
            /> 
              <Typography style={styles.subCate}>
                {item.service_subcategory_name}
              </Typography>
            </TouchableOpacity>
          ))}
        <TouchableOpacity
          data-test-id="edit-airports"
          onPress={() => this.props.navigation.navigate("AirportSettings")}
          style={styles.selectAllAirport}
        >
          <EditOutlinedIcon name="edit" style={styles.editIcon} />
          <Typography style={styles.editAirport}>Edit airports</Typography>
        </TouchableOpacity>
      </View>}
    </View>
    )
  }

  renderSelectTestsView() {
    const { allTests, isSelectTestTypeOpen, userSelectedTests} = this.state
    return (
      <View style={styles.borderBottom}>
        <TouchableOpacity
          data-test-id="select-tests"
          onPress={() => this.toggleSelectTest()}
        >
          <View style={styles.subContainer2}>
            <View style={styles.flexCenter}>
              <AirplaneTicketOutlinedIcon style={webStyle.icons} />
              <View style={{ marginLeft: 25 }}>
                <Typography
                  style={{
                    fontSize: 16,
                    color: this.getSelectedTestColor(),
                    fontWeight: "500",
                  }}
                >
                  {!isSelectTestTypeOpen && userSelectedTests && userSelectedTests.length > 0
                    ? this.formatSelectedTest(userSelectedTests)
                    : "Select test type(s)"}
                </Typography>
              </View>
            </View>
            <Box sx={{cursor: "pointer"}}>
            <Image
              style={styles.arrowStyle}
              source={isSelectTestTypeOpen ? upArrow : downArrow}
            />
            </Box>
          </View>
        </TouchableOpacity>
        {
          isSelectTestTypeOpen && <View style={styles.padding}>
            <TouchableOpacity
              data-test-id="selectAllTests"
              onPress={() => this.toggleSelectAllTests()}
              style={styles.selectAllTest}
            >
              <Image
                source={this.handleAllCheckIconforTests()}
                style={styles.checked}
              />
              <Typography style={styles.airportText}>All tests</Typography>
            </TouchableOpacity>
            {allTests.length >= 1 &&
              allTests.map((item, index) => (
                <TouchableOpacity
                  data-test-id={`select-single-test-${index}`}
                  onPress={() => this.handleSelectTests(item)}
                  key={item.service_subcategory_id}
                  style={styles.singleTest}
                >
                  <Image
                    source={
                      this.handleTestCheckbox(item.id) ? checkedBox : unCheckedBox
                    }
                    style={styles.checked}
                  />
                  <Typography style={styles.airportText}>
                    {item.designation ?? item.service_subcategory_name}
                  </Typography>
                </TouchableOpacity>
              ))}
              <TouchableOpacity
                data-test-id="redirectToEditTests"
                onPress={() => this.props.navigation.navigate("TestSettings")}
                style={styles.settings}
              >
              <EditOutlinedIcon name="edit" style={styles.editIcon} />
              <Typography style={styles.editAirport}>Edit tests</Typography>
            </TouchableOpacity>
          </View>
        }
      </View>
    )
  }

  renderNotifyAllSwitch() {
    const { shouldNotifyAlluser } = this.state;
    return (
    <View  style={styles.borderBottom}>
      <Box data-test-id="calenderopenbtn" >
        <View style={styles.subContainerNotify}>
          <View style={styles.flexCenter}>
            <NotificationsOutlinedIcon style={webStyle.icons} />
            <View style={{ marginLeft: 25 }}>
              <Typography
                style={styles.notifyAll}
              >
                Notify all on waitlist
              </Typography>
            </View>
          </View>
          <Switch
            data-test-id="shouldNotifyAlluser"
            checked={shouldNotifyAlluser}
            onChange={(event: any) => this.handleToggleSwitch(event.target.checked)}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        </View>
      </Box>
    </View>
  )}

  renderGradeView() {
    const { filteredGradeData } = this.state
    return (
      <Box sx={webStyle.singleContainerView}>
        <Chip
          size="small"
          label="All grades of certificate"
          data-test-id={`select_all_grade`}
          onClick={() => this.toggleSelectAllGrades()}
          style={{
            backgroundColor: this.handleAllGradesCheckChipColor(), 
            color: this.handleAllGradesCheckTextColor(),
            fontWeight:400,
            fontSize: 14,
            margin:"5px",
            height: "30px"
          }}
        />
        {
          filteredGradeData.map((item: Aircraft, index: number) =>
            <Chip
              size="small"
              key={index}
              label={item.name}
              data-test-id={`select_grade_${index}`}
              onClick={() => this.toggleGradeSelection(item.id)}
              style={{
                backgroundColor: this.toggleChipBackgroundColor(item.selected), 
                color: this.toggleChipColor(item.selected),
                fontWeight:400,
                fontSize: 14,
                margin:"5px",
                height: "30px"
              }}
            />
          )
        }
      </Box>
    )
  }

  renderCategoryView() {
    const { filteredCategoryData } = this.state
    return (
      <Box sx={webStyle.singleContainerView}>
         <Chip
          size="small"
          label="All categories"
          data-test-id={`select_all_categories`}
          onClick={() => this.toggleSelectAllCategories()}
          style={{
            backgroundColor: this.handleAllCategoriesCheckChipColor(), 
            color: this.handleAllCategoriesCheckTextColor(),
            fontWeight:400,
            fontSize: 14,
            margin:"5px",
            height: "30px"
          }}
        />
        {
          filteredCategoryData.map((item: Aircraft, index: number) =>
            <Chip
              size="small"
              key={index}
              label={item.name}
              onClick={() => this.toggleCategorySelection(item.id)}
              data-test-id={`select_category_${index}`}
              style={{
                backgroundColor: this.toggleChipBackgroundColor(item.selected), 
                color: this.toggleChipColor(item.selected),
                fontWeight:400,
                fontSize: 14,
                margin:"5px",
                height: "30px"
              }}
            />
          )
        }
      </Box>
    )
  }

  renderClassView() {
    const { filterClassData } = this.state
    return (
      <><Box sx={webStyle.singleContainerView}>
        <Chip
          size="small"
          label="All classes"
          data-test-id={`select_all_class`}
          onClick={() => this.toggleSelectAllClass()}
          style={{
            backgroundColor: this.handleAllClassCheckChipColor(), 
            color: this.handleAllClassCheckTextColor(),
            fontWeight:400,
            fontSize: 14,
            margin:"5px",
            height: "30px"
          }}
        />
        {filterClassData.map((item: Aircraft, index: number) => <Chip
          size="small"
          key={index}
          label={item.name}
          data-test-id={`select_class_${index}`}
          onClick={() => this.toggleClassSelection(item.id)}
          style={{
            backgroundColor: this.toggleChipBackgroundColor(item.selected),
            color: this.toggleChipColor(item.selected),
            fontWeight: 400,
            fontSize: 14,
            margin: " 5px",
            height: "30px"
          }} />
        )}
      </Box><Box sx={webStyle.boxPaddingBottom}></Box></>
    )
  }

  renderTestCategories() {
    const { isGradePopupOpen, isClassPopupOpen, isCategoryPopupOpen } = this.state
    return (
      <Box sx={webStyle.borderBottomStyle}>
        <Box data-test-id="toggleGradePopupBtn" onClick={() => this.handleToggleGradePopup()} sx={[webStyle.singleTestCategoryTouchable, this.handleGradeHeaderBg()]}>
          <Typography style={styles.testCategoryTitle}>GRADE OF CERTIFICATE</Typography>
          <Image source={this.handleGradeIcon()} style={styles.imageStyle} />
        </Box>

        {isGradePopupOpen && this.renderGradeView()}

        <Box data-test-id="toggleCategoryPopupBtn" onClick={() => this.handleToggleCategoryPopup()} sx={[webStyle.singleTestCategoryTouchable, this.handleCategoryHeaderBg()]}>
          <Typography style={styles.testCategoryTitle}>CATEGORY</Typography>
          <Image source={this.handleCategoryIcon()} style={styles.imageStyle} />
        </Box>

        {isCategoryPopupOpen && this.renderCategoryView()}

        <Box data-test-id="toggleClassPopupBtn" onClick={() => this.handleToggleClassPopup()} sx={[webStyle.singleTestCategoryTouchable, this.handleClassHeaderBg()]}>
          <Typography style={styles.testCategoryTitle}>CLASS</Typography>
          <Image source={this.handleClassIcon()} style={styles.imageStyle} />
        </Box>

        {isClassPopupOpen && this.renderClassView()}
    </Box>
  )}
  users = [
    { id: 1, name: "Alan Brooks", avatar: "avatar", calendar: "calender2" },
    { id: 2, name: "Alan Brooks", avatar: "avatar", calendar: "calender2" },
  ];

  // Toggle individual checkbox
  toggleSelect = (id: number) => {
    this.setState((prevState) => {
      const { selectedd } = prevState;
      const newSelected = selectedd.includes(id)
        ? selectedd.filter((item) => item !== id) // Remove from selection
        : [...selectedd, id]; // Add to selection
    
      return {
        selectedd: newSelected, // ✅ Only updating the required properties
        selectAll: newSelected.length === this.users.length, // ✅ Check if all are selected
      };
    });
  };

  // Toggle "Select All" checkbox
  handleSelectAll = () => {
    this.setState((prevState) => {
      const selectAll = !prevState.selectAll;
      return {
        selectAll,
        selectedd: selectAll ? this.users.map((user) => user.id) : [], // Select all or clear selection
      };
    });
  };


  renderTestCategories1() {
    return (
      <Box sx={webStyle.borderBottomStyle}>
        <div style={{  marginLeft:"35px",  marginBottom:'20px',display:'flex',gap:'4px'}}>
        <Chip
          size="small"
          label={
            <span style={{ display: "flex", alignItems: "center" }}>
             All DPEs (100)            </span>
          }       
            
          style={{
            backgroundColor:"#0074BF", 
            color: "#FFFFFF",
            fontWeight:400,
            fontSize: 14,
            height: "30px",
          }}
        />
         <Chip
          size="small"
          label={
            <span style={{ display: "flex", alignItems: "center" }}>
             With availability (35)    
                   </span>
          }        
          style={{
            backgroundColor: "#0074BF", 
            color: "#FFFFFF",
            fontWeight:400,
            fontSize: 14,
            height: "30px",
          }}
        />
</div>
<div style={{ fontFamily: "Montserrat" }}>
        <div style={{ display: "flex", alignItems: "center", marginBottom: "8px", marginLeft: "20px" }}>
          <input type="checkbox" checked={this.state.selectAll} onChange={this.handleSelectAll} />
          <span style={{ marginLeft: "8px", fontWeight: "400",fontSize:"16px",fontFamily:"Montserrat" }}>Select All</span>
        </div>

        {this.users.map((user) => (
          <div
            key={user.id}
            style={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              marginBottom: "4px",
            }}
          >
           

            <div style={{ display: "flex", gap: "4px", alignItems: "center" }}>
            <input
              type="checkbox"
              checked={this.state.selectedd.includes(user.id)}
              onChange={() => this.toggleSelect(user.id)}
            />
              <img
                style={{ width: "20px", height: "20px", borderRadius: "50%" }}
                src={avatar}
                alt="Avatar"
              />
              <div style={{ fontWeight: 400, fontSize: "16px" }}>{user.name}</div>
            </div>

            {/* Actions */}
            <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
              <div
                style={{
                  fontWeight: 600,
                  fontSize: "12px",
                  display: "flex",
                  border: "1px solid #0074BF",
                  borderRadius: "20px",
                  color: "#0074BF",
                  padding: "6px 10px",
                }}
              >
                Join waitlist
              </div>
              <img
                style={{ width: "34px", height: "34px", borderRadius: "50%" }}
                src={calender2}
                alt="Calendar"
              />
            </div>
          </div>
        ))}
      </div>

    </Box>
  )}

  renderSingleAirport(item: Aircraft) {
    return (
      <Box data-test-id="singleAirportChecklist" key={item.id} sx={webStyle.singleCheckList} onClick={() => this.toggleAirportSelection(item.id)}>
        <Image source={item.selected ? checkedBox : unCheckedBox} style={styles.checked}/>
        <Typography style={webStyle.singleCheckListTitle}>{item.name}</Typography>
      </Box>
    );
  }


  renderAirportList() {
    return (
        <Box sx={webStyle.listView}>
          <Box data-test-id="allAirports" sx={webStyle.singleCheckList} onClick={() => this.toggleSelectAllAirport()}>
            <Image source={this.handleAllAirportCheckIcon()} style={styles.checked}/>
            <Typography style={webStyle.singleCheckListTitle}>All airports</Typography>
          </Box>
          {this.state.filteredAirports.map(item => this.renderSingleAirport(item))}
        </Box>
    );
  }

  renderAirportView() {
    return (
      <><Box>
        {this.renderSearchView(this.handleSearchAirports, this.state.searchQueryAirport, "Search airport(s)")}
        <Box sx={webStyle.borderBottomSearch}></Box>
        {this.renderAirportList()}
      </Box><Box sx={webStyle.borderBottomStyle}></Box></>
    );
  }
  

  filterTests() {
    const { isTestPopupOpen } = this.state
    return (
      <Box>
        <Box data-test-id="toggleTestPopupBtn" onClick={() => this.handleToggleTestPopup()}  sx={webStyle.singleFilterBtn}>
          <Box style={styles.singleFilterBtn_left}>
            <Box sx={webStyle.statusIcon}>
              <AirplaneTicketOutlinedIcon style={webStyle.icons}/>
            </Box>
            <Box style={styles.singleFilterBtn_left_text}>
              <Typography style={this.handleTestDrodownColor()}>Type of test(s)</Typography>
              {!isTestPopupOpen && <Typography style={styles.singleFilterBtn_left_helpText}>All types of tests</Typography>}
            </Box>
          </Box>
          <Box>
          <Box sx={{cursor: "pointer"}}>
          <Image
            style={[styles.arrowStyle, { tintColor: this.handleTestDrodownIconColor() }]}
            source={isTestPopupOpen ? upArrow : downArrow}
          />
          </Box>
          </Box>
        </Box>
        {isTestPopupOpen && this.renderTestCategories()}
      </Box>
  )}
  dpeMatches() {
    const { isTestPopupOpen } = this.state
    return (
      <Box>
        <Box data-test-id="toggleTestPopupBtn1" onClick={() => this.handleToggleTestPopup()}  sx={webStyle.singleFilterBtn}>
          <Box style={styles.singleFilterBtn_left}>
            <Box sx={webStyle.statusIcon}>
              {/* <AirplaneTicketOutlinedIcon style={webStyle.icons}/> */}
              <img src={group_image}/>
              
            </Box>
            <Box style={styles.singleFilterBtn_left_text}>
              <Typography style={this.handleTestDrodownColor()}>DPE matches</Typography>
            </Box>
          </Box>
          <Box>
          <Box sx={{cursor: "pointer"}}>
          <Image
            style={[styles.arrowStyle, { tintColor: this.handleTestDrodownIconColor() }]}
            source={isTestPopupOpen ? upArrow : downArrow}
          />
          </Box>
          </Box>
        </Box>
        {isTestPopupOpen && this.renderTestCategories1()}
      </Box>
  )}

  filterAircrafts() {
    const { isAircraftPopupOpen } = this.state
    return (
      <Box>
        <Box data-test-id="toggleAircraftPopup" onClick={() => this.handleToggleAircraftPopup()} sx={webStyle.singleFilterBtn}>
          <Box style={styles.singleFilterBtn_left}>
            <Box sx={webStyle.statusIcon}>
              <img src={airplane} alt="airplane" style={webStyle.icons}/>
            </Box>
            <Box style={styles.singleFilterBtn_left_text}>
              <Typography style={this.handleAircraftDrodownColor()}>Aircraft type(s)</Typography>
              {!isAircraftPopupOpen && <Typography style={styles.singleFilterBtn_left_helpText}>All aircraft types</Typography>}
            </Box>
          </Box>
          <Box sx={{cursor: "pointer"}}>
            <Image
              style={[styles.arrowStyle, { tintColor: this.handleAircraftDrodownIconColor() }]}
              source={isAircraftPopupOpen ? upArrow : downArrow}
            />
          </Box>
        </Box>
        {isAircraftPopupOpen && this.renderAircraftView()}
      </Box>
    )
  }

  filterAirports() {
    const { isAirportPopupOpen } = this.state
    return (
      <Box>
        <Box data-test-id="toggleAirportPopup" onClick={() => this.handleToggleAirportPopup()} sx={webStyle.singleFilterBtn}>
          <Box style={styles.singleFilterBtn_left}>
            <Box sx={webStyle.statusIcon}>
                <img src={take_off_airplane} alt="airplane" style={webStyle.icons}/>
            </Box>
            <Box style={styles.singleFilterBtn_left_text}>
              <Typography style={ this.handleAirportDrodownColor()}>Airport(s)</Typography>
              {!isAirportPopupOpen && <Typography style={styles.singleFilterBtn_left_helpText}>All airports</Typography>}
            </Box>
          </Box>
          <Box sx={{cursor: "pointer"}}>
            <Image
            style={[styles.arrowStyle, { tintColor: this.handleAirportDrodownIconColor() }]}
            source={isAirportPopupOpen ? upArrow : downArrow}
          />
          </Box>
        </Box>
        {isAirportPopupOpen && this.renderAirportView()}
      </Box>
    )
  }

  renderSingleAircraft(item: Aircraft) {
    return (
      <Box data-test-id="singleAircraftChecklist" key={item.id} sx={webStyle.singleCheckList} onClick={() => this.toggleAircraftSelection(item.id)}>
        <Image source={item.selected ? checkedBox : unCheckedBox} style={styles.checked}/>
        <Typography style={webStyle.singleCheckListTitle}>{item.name}</Typography>
      </Box>
    );
  }

  renderAircraftList() {
    return (
        <Box sx={webStyle.listView}>
          <Box data-test-id="allAircraft" sx={webStyle.singleCheckList} onClick={() => this.toggleSelectAllAircraft()}>
            <Image source={this.handleAllAircraftCheckIcon()} style={styles.checked}/>
            <Typography style={webStyle.singleCheckListTitle}>All aircraft types</Typography>
          </Box>
          {this.state.filteredAircrafts.map(item => this.renderSingleAircraft(item))}
        </Box>
    );
  }

  renderAircraftView() {
    return (
      <>
      <Box>
        {this.renderSearchView(this.handleSearchAircraft, this.state.searchQuery, "Search aircraft type(s)")}
        <Box sx={webStyle.borderBottomSearch}></Box>
        {this.renderAircraftList()}
      </Box>
      <Box sx={webStyle.borderBottomStyle}></Box>
      </>
  )}

  renderSearchView(searchFn: any, value: string, placeholder: string) {
    return (
      <Box sx={webStyle.searchContainerForFilters}>
        <SearchOutlinedIcon style={webStyle.icons} />
        <CustomSearchField
          style={webStyle.searchPlaceholderFilters}
          data-test-id="search-filter-item"
          placeholder={placeholder}
          value={value}
          onChange={(event:any)=> {searchFn(event.target.value)}}
        />
      </Box>
    );
  }
  
  render() {
    const {userRole} = this.state;
    return (
        <>
        <Box sx={webStyle.headerContainer} >
        <Snackbar
            open={this.state.isAlert}
            autoHideDuration={3000}
            anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
            onClose={this.oncloseAlert}
            data-test-id="alertTestId"
          >
            <CustomBoxSnackbar alertType={this.state.alertType as any}>
                <img 
                  src={this.state.alertMsg === "Availabilities created successfully!" ? greenTick : cross}
                  alt={this.state.alertMsg === "Availabilities created successfully!" ? "Success" : "Error"}
                  style={webStyle.snackbarImage as any}
                />
              {this.state.alertMsg}
            </CustomBoxSnackbar>
          </Snackbar>
          <Typography sx={webStyle.headerText}>Calendar</Typography>
          <Box style={{display:'flex',alignItems:'center',gap:'20px'}}>
          <Typography sx={webStyle.headerText1}>Sync calendars</Typography>
          
            
          <Typography sx={webStyle.headerText1}>
          
          <img src={calendar1} style={{width:'21px',height:'21px'}}/>
            Today
            </Typography>

            {userRole === "dpe" ? (<StyledButtonContainer>
              <CustomButton
                data-test-id="new-availability"
                variant="contained"
                className={"newAvailabilityButton"}
                color="primary"
                onClick={() => this.setState({ open: true,  todayDate: moment().format("YYYY-MM-DD")})}
              >
                <img src={plusIcon} style={{width:'21px',height:'21px'}}/>
                New availability
              </CustomButton>
            </StyledButtonContainer>) : (
              <StyledButtonContainer>
              <CustomButton
                    data-test-id="filters"
                    variant="contained"
                    className={"filtersButton"}
                    onClick={() => this.setState({ isFiltersDialogOpen: true, anchorEl: this.buttonRef.current})}
                  >
                    <img src={filter} style={webStyle.filtersImage}/>
                    Filter
                  </CustomButton>
                  </StyledButtonContainer>
            )}
          </Box>
        </Box>
        <Box ref={this.buttonRef} sx={webStyle.refBox}></Box>
    <Grid container spacing={2} style={{padding:'50px 50px 50px 50px'}} >
      <Grid item xs={12} sm={12} lg={4} md={4} >
        
        <Box>
        <div style={calendarStyles}>
              <Calendar onChange={this.handleDateChange} value={this.state.date} />
            </div>    
          </Box>



        <StyledContainer style={{padding:'20px 24px 20px 24px',marginTop:'24px'}}>
          <Box style={{display:'flex',justifyContent:'space-between',width:'100%', marginTop:'24px !important'}}>

            <Box style={{color:'#001C55',fontWeight:700,fontSize:'16px',fontFamily:'Montserrat'}}>
        Upcoming booked slots
            </Box>
        <Box style={{display:'flex',alignItems:'center',gap:'20px'}}>
        <img src={left} style={{width:'20px',height:'20px'}}/>
        <img src={right} style={{width:'20px',height:'20px'}}/>
        </Box>
        </Box>

        <Box style={{display:'contents'}}>
        <img src={calendar2} style={{width:'60px',height:'60px',marginTop:'55px',marginBottom:'20px'}}/>
        <Typography sx={webStyle.text}>Nothing booked yet!</Typography>
        <Typography sx={webStyle.text1}>Once your availabilities are booked,<br/> they'll appear here.</Typography>
        </Box>
        </StyledContainer>
      </Grid>

      <Grid item xs={12} sm={12} lg={8} md={8}>
        <StyledContainerBigCal>
          <BigCalender navigation={this.props.navigation} id={""}/>
        </StyledContainerBigCal>
      </Grid>
  
    </Grid>
    <Dialog open={this.state.open} 
        onClose={() => this.onCloseNewAvailabilityDialog()} 
        maxWidth="sm" fullWidth 
        PaperProps={{
            style: { borderRadius: 16, width: "100%" }
        }} >
          <DialogTitle sx={styles.dialogTitle}>
            New availability
            <IconButton
              data-test-id="close-icon"
              aria-label="close"
              onClick={() => this.onCloseNewAvailabilityDialog()}
              sx={{ position: 'absolute', right: 18, top: 8, }}
            >
              <CloseIcon sx={styles.closeIcon} />
            </IconButton>
          </DialogTitle>
          <DialogContent style={{scrollbarWidth:"thin"}} sx={styles.dialogContent} >
            {this.renderCalenderView()}
            {this.renderTimeSlots()}
            <View
              data-test-id="dateContainer"
              style={styles.dateContainer}
            >
              <View style={styles.subContainer2}>
                <TouchableOpacity
                  data-test-id="addTimeslotbtn"
                  onPress={() => this.handleAddTimeSlot()}
                >
                  <View style={styles.flexCenter}>
                    <AddCircleOutlineIcon style={styles.editIcon} />
                    <View style={{ marginLeft: 25 }}>
                      <Typography
                        style={styles.timeslotTypo}
                      >
                        Add another timeslot
                      </Typography>
                    </View>
                  </View>
                </TouchableOpacity>
              </View>
            </View>
            {this.renderTimezoneOverlay()}
          </DialogContent>
        </Dialog>

        <Dialog open={this.state.isSetDetailsOpen} onClose={() => {this.setState({ isSetDetailsOpen: false }) ; this.handleCancel()}} maxWidth="sm" fullWidth PaperProps={{
          style: { borderRadius: 16, width: "100%" }
        }} >
          <DialogTitle sx={styles.dialogTitle}>
            <TouchableOpacity
              data-test-id="backBtn"
              onPress={() => {
                this.setState({ isSetDetailsOpen: false, open: true });
              }}
            >
              <img src={leftArrow} style={styles.leftArrow} />
            </TouchableOpacity>
            New availability
            <IconButton
              aria-label="close"
              data-test-id="cancelIcon"
              onClick={() => { 
                this.setState({ isSetDetailsOpen: false }) 
                this.handleCancel()
              }}
              sx={{ position: 'absolute', right: 18, top: 8, }}
            >
              <CloseIcon sx={styles.closeIcon} />
            </IconButton>
          </DialogTitle>
          <DialogContent sx={styles.dialogContent} >
            <View style={{ height: "inherit" }}>
              <View style={styles.detailsContainer}>
                <Typography style={styles.detailsText} >
                  DETAILS
                </Typography>
              </View>
              {this.renderSelectAirportView()}
              {this.renderSelectTestsView()}
              {this.renderNotifyAllSwitch()}
              {this.renderSelectNotifyPeoples()}
            </View>
            <View style={styles.subContainer2}>
                <StyledButtonContainer>
                  <CustomButton
                    data-test-id="cancel-details"
                    variant="outlined"
                    className="cancelButton"
                    onClick={() => this.handleCancelSetDetails()}>
                      Cancel
                  </CustomButton>
                </StyledButtonContainer>
              
                <StyledButtonContainer>
                <CustomButton
                  data-test-id="save-availability"
                  variant="contained"
                  className={this.shouldEnableCreateAvailablityBtn() ? "setDetailsButton" : "saveButton"}
                  color="primary"
                  onClick={this.shouldEnableCreateAvailablityBtn() ? () => this.handleSaveAvailability() : undefined}
                >
                  Save availability
                </CustomButton>
                </StyledButtonContainer>
            </View>
          </DialogContent>
        </Dialog>

        <Popover
          id="Filters"
          open={this.state.isFiltersDialogOpen} 
          anchorEl={this.state.anchorEl}
          onClose={() => {
            this.setState({ isFiltersDialogOpen: false})
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          slotProps={{
            paper: {
              sx: {
                width: "420px",
                borderRadius: "20px",
              },
            },
          }}
        >
          <CustomPopoverBox>
            <Typography style={styles.filterScreen_title}>Filter</Typography>
            <CustomButtonReset className={this.state.isResetEnabled ? "enabledResetButton" : "disabledResetButton"} data-test-id="resetFilterBtn" onClick={() => this.resetFilter()}>
              Reset all
            </CustomButtonReset>
          </CustomPopoverBox>
          <CustomContainerFilters>
            {this.filterTests()}
            {this.filterAircrafts()}
            {this.filterAirports()}
          </CustomContainerFilters>
          <StyledButtonContainer>
              <CustomViewDpeButton>
                <CustomButton
                  data-test-id="update-details"
                  variant="contained"
                  className={"viewDpeButton"}
                  color="primary"
                  onClick={this.handleOpenDpePopup}
                  >
                    View DPE matches
                </CustomButton>
              </CustomViewDpeButton>
            </StyledButtonContainer>
        </Popover>

        <Popover
          id="DpeMatchesPopup"
          open={this.state.isDpePopupOpen}
          anchorEl={this.state.anchorElDpe}
          onClose={this.handleCloseDpePopup}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          slotProps={{
            paper: {
              sx: {
                width: "448px",
                borderRadius: "20px",
              },
            },
          }}
       
        >

         <CustomPopoveerBox>
           <img 
           data-test-id="isDpePopupOpen23"
           onClick={this.handleCloseDpePopup24}

           style={{width:'24px',height:'24px'}} src={leftArrow}/>
             
            <Typography style={styles.filterScreen_title}>Filter</Typography>
           
          </CustomPopoveerBox>

          <CustomPopoverrBox>
            <Typography style={styles.filterScreen_title1}>FILTERS IN USE</Typography>
            <CustomButtonReset>
            <img src={button_edit} alt="airplane" style={webStyle.icons}/>
            </CustomButtonReset>
          </CustomPopoverrBox>
          <CustomContainerFilters>
          <Box sx={webStyle.singleContainerView}>
        <Chip
          size="small"
          label={
            <span style={{ display: "flex", alignItems: "center" }}>
              <img src={air1} alt="air1" style={{ width: 16, height: 16, marginRight: 5 }} />
              Private - Airplane - SEL (+5)            </span>
          }      
          // onClick={() => this.toggleSelectAllGrades()}
          style={{
            backgroundColor: "#E0F2F8", 
            color: "#0074BF",
            fontWeight:400,
            fontSize: 14,
            margin:"5px",
            height: "30px"
          }}
        />
         <Chip
          size="small"
          label={
            <span style={{ display: "flex", alignItems: "center" }}>
              <img src={air2} alt="air2" style={{ width: 16, height: 16, marginRight: 5 }} />
              All aircraft types            </span>
          }      
          // onClick={() => this.toggleSelectAllGrades()}
          style={{
            backgroundColor:"#E0F2F8", 
            color: "#0074BF",
            fontWeight:400,
            fontSize: 14,
            margin:"5px",
            height: "30px"
          }}
        />
         <Chip
          size="small"
          label={
            <span style={{ display: "flex", alignItems: "center" }}>
              <img src={air3} alt="air3" style={{ width: 16, height: 16, marginRight: 5 }} />
              All airports            </span>
          }   
          // onClick={() => this.toggleSelectAllGrades()}
          style={{
            backgroundColor: "#E0F2F8", 
            color: "#0074BF",
            fontWeight:400,
            fontSize: 14,
            margin:"5px",
            height: "30px"
          }}
        />
       
      </Box>
            {this.dpeMatches()}
   
            <StyledButtonContainer>
              <CustomViewDpeButton>
                <CustomButton
                  data-test-id="update-details"
                  variant="contained"
                  className={"viewDpeButton"}
                  color="primary"
                  onClick={this.handleCloseDpePopup}
                  >
               Apply filters and view calendar           
           </CustomButton>
              </CustomViewDpeButton>
            </StyledButtonContainer>
          </CustomContainerFilters>
        </Popover>
        </>
    );
  }
}

const CustomViewDpeButton = styled(Box)({
  display:"flex",
  justifyContent:"center",
  "& .viewDpeButton" :{
    height: "50px",
    fontSize: "16px",
    padding: "0px",
    fontWeight: 700,
    fontFamily: "Montserrat",
    textTransform: "none",
    borderRadius: "20px",
    color: "#ffffff",
    background: "#0074BF",
    margin:"20px",
    width:"345px"
 }
})

const CustomContainerFilters = styled(Box)({
  overflow:"auto",
  maxHeight:"610px",
  scrollbarWidth:"thin",
  "& .pointerIcon": {
    cursor: "pointer"
  }
})

const CustomPopoverBox = styled(Box)({
  padding:"25px",
  display:"flex",
  alignItems:"center",
  justifyContent:"space-between",
  borderBottom:"1px solid #E2E8F0",
  "& .disabledResetButton" :{
    color: "#00AFEF",
    opacity:"50%"
  },
  "& .enabledResetButton" :{
    opacity:"100%"
  }
}) 
const CustomPopoveerBox = styled(Box)({
  padding:"25px",
  display:"flex",
  alignItems:"center",
  gap:'20px',
  // justifyContent:"space-between",
  borderBottom:"1px solid #E2E8F0",
  "& .disabledResetButton" :{
    color: "#00AFEF",
    opacity:"50%"
  },
  "& .enabledResetButton" :{
    opacity:"100%"
  }
}) 
const CustomPopoverrBox = styled(Box)({
  padding:"10px 30px 10px 30px",
  backgroundColor:'#F1F5F9',
  display:"flex",
  alignItems:"center",
  justifyContent:"space-between",
  borderBottom:"1px solid #E2E8F0",
  "& .disabledResetButton" :{
    color: "#00AFEF",
    opacity:"50%"
  },
  "& .enabledResetButton" :{
    opacity:"100%"
  }
}) 

const CustomButtonReset = styled(Button)({
  fontSize: 16,
  fontWeight: "500",
  border:"none",
  textTransform:"none"
})

const StyledButtonContainer = styled(Box)({
  "& .filtersButton" : {
    height: "40px",
    fontSize: "16px",
    padding: "0px",
    fontWeight: 700,
    fontFamily: "Montserrat",
    textTransform: "none",
    color: "#ffffff",
    borderRadius:'20px',
    display:'flex',
    backgroundColor:'#0092C8',
    width: "110px"
  },
  "& .newAvailabilityButton" : {
    height: "40px",
    fontSize: "16px",
    padding: "0px",
    fontWeight: 700,
    fontFamily: "Montserrat",
    textTransform: "none",
    color: "#ffffff",
    border:'2px solid #00AFEF',
    borderRadius:'20px',
    display:'flex',
    backgroundColor:'#00AFEF',
    width:"190px"
  },
  "& .saveButton": {
      height: "50px",
      fontSize: "16px",
      padding: "0px",
      fontWeight: 700,
      fontFamily: "Montserrat",
      textTransform: "none",
      opacity: "30%",
      borderRadius: "20px",
      color: "#ffffff",
      background: "#0074BF",
    },
  "& .setDetailsButton": {
      height: "50px",
      fontSize: "16px",
      padding: "0px",
      fontWeight: 700,
      fontFamily: "Montserrat",
      textTransform: "none",
      borderRadius: "20px",
      color: "#ffffff",
      background: "#0074BF",
    },
  "& .deleteButton": {
      height: "50px",
      fontSize: "16px",
      padding: "0px 15px",
      fontWeight: 700,
      fontFamily: "Montserrat",
      textTransform: "none",
      borderRadius: "20px",
      color: "#ffffff",
      background: "#0074BF",
      width:"226px",
    },
  "& .cancelButton": {
      height: "50px",
      fontSize: "16px",
      padding: "0px",
      fontWeight: 700,
      fontFamily: "Montserrat",
      textTransform: "none",
      borderRadius: "20px",
      color:"#0074BF",
      border:"2px solid #0074BF"
    },
  "& .deleteCancelButton": {
      height: "50px",
      fontSize: "16px",
      padding: "0px",
      fontWeight: 700,
      width:"218px",
      fontFamily: "Montserrat",
      textTransform: "none",
      borderRadius: "20px",
      color:"#0074BF",
      border:"2px solid #0074BF"
    },
})
const StyledContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  // height: "fit-content",
  alignItems: "center",
  // maxWidth: "clamp(400px, 50vw, 860px)",
  background: "#ffffff",
  borderRadius: "20px",
  marginTop: "40px",
  // width: "100%",
  // padding: "20px",

  "& .form": {
    width: "100%",
    maxWidth: "80%",
    gap: "15px",
    flexDirection: "column",
    // minWidth:"400px",
    display: "flex",

    "& .fieldContainer": {
      flexDirection: "column",
      gap: "5px",
      display: "flex",
      "& .fieldLabel": {
        color: "#334155",
        fontSize: "16px",
        fontFamily: "Montserrat",
        fontWeight: 600,
      },
    },

    "& .saveButton": {
      opacity: "30%",
      padding: "0px",
      height: "50px",
      fontSize: "16px",
      fontWeight: 700,
      fontFamily: "Montserrat",
      textTransform: "none",
      borderRadius: "20px",
      color: "#ffffff",
      background: "#0074BF",
    },
  },

  "@media (max-width: 900px)": {
    maxWidth: "clamp(300px, 90vw, 800px)",
  },
  "@media (max-width: 600px)": {
    // maxWidth: "100%",
    // height:"100%",
    "& .form": {
      maxWidth: "100%",
      minWidth:"290px",
    }
  }

});
const StyledContainerBigCal = styled(Box)({
  // height: "fit-content",
  // maxWidth: "clamp(400px, 50vw, 860px)",
  // marginTop: "40px",
  // width: "100%",
  alignItems: "center",
  flexDirection: "column",
  background: "#ffffff",
  display: "flex",
  borderRadius: "20px",
  padding: "30px 30px 30px 30px",

  "& .form": {
    // minWidth:"400px",
    maxWidth: "80%",
    gap: "15px",
    flexDirection: "column",
    width: "100%",
    display: "flex",

    "& .fieldContainer": {
      flexDirection: "column",
      display: "flex",
      gap: "5px",
      "& .fieldLabel": {
        color: "#334155",
        fontSize: "16px",
        fontWeight: 600,
        fontFamily: "Montserrat",
      },
    },

    "& .saveButton": {
      height: "50px",
      fontSize: "16px",
      padding: "0px",
      fontWeight: 700,
      fontFamily: "Montserrat",
      textTransform: "none",
      opacity: "30%",
      borderRadius: "20px",
      color: "#ffffff",
      background: "#0074BF",
    },
  },

  "@media (max-width: 900px)": {
    maxWidth: "clamp(300px, 90vw, 800px)",
  },
  "@media (max-width: 600px)": {
    // maxWidth: "100%",
    // height:"100%",
    "& .form": {
      maxWidth: "100%",
      minWidth:"290px",
    }
  }

});
const calendarStyles = {
   '--react-calendar-weekdays-decoration': 'none',
   color: '#64748B',
   overflow: 'hidden',
   borderRadius: '20px', 
  border: 'none',
  backgroundColor: 'white'
 
};


const CustomBox = styled(Box)({
  // position:"fixed",
  top: "100%",
  left: 300,
  zIndex: 1000,
  background: "#fff",
  // boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
  borderRadius: "8px",
  padding: "10px"
})
const CustomButton = styled(Button)({
  textTransform: "capitalize",
  fontWeight: 700,
  fontSize: 16,
  width: 258,
  borderRadius: 20,
  display:"flex",
  alignItems:"center"
})
const CustomTextField = styled(TextField)({
  border: "none",
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  "& .MuiOutlinedInput-input":{
    cursor:"pointer",
  }
})
const CustomSearchField = styled(TextField)({
  border: "none",
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none"
  },
  "& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {
    padding:0
  }
})

const webStyle = {
  listView:{ height: 255, overflow:"auto", scrollbarWidth:"thin"},
  filtersImage: {width:'21px',height:'21px', marginRight: 8},
  refBox:{
    height:10,
    marginRight:"50px"
  },
  borderBottomStyle:{
    borderBottom:"1px solid #E2E8F0",
    paddingTop:"20px"
  },
  boxPaddingBottom:{
    marginBottom: "20px"
  },
  borderBottomSearch:{
    borderBottom:"1px solid #E2E8F0",
  },
  aircraftView:{
    display:"flex",
    alighnItems:"center",
    marginLeft:"20px"
  },
  singleContainerView: {
    flexDirection: "row",
    flexWrap: "wrap",
    display: "flex",
    alignItems: "center",
    width:350,
    padding: "0px 23px",
  },
  singleFilterBtn: {
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "20px 35px 10px 25px",
    borderBottom:"1px solid #E2E8F0",
    display:"flex",
    cursor:"pointer"
  },
  availabilityStatus:{width:20, height:20, paddingBottom:"2px"},
  deleteText:{
    paddingBottom:"10px",
    paddingTop:"50px",
    padding:"40px",
    display:"flex",
    justifyContent:"center",
    flexDirection:"column",
    borderBottom:"1px solid #E2E8F0"
  },
  sections:{
    padding:"2px 10px",
    height:"64px",
  },
  snackbarImage:{
    width: "20px", 
    height: "20px",
    marginRight: "8px", 
    verticalAlign: "middle",
    objectFit:'contain'
  },
  statusIcon:{ paddingBottom:"6px", width:"20px", height:"20px",},
  title:{
    fontWeight:700,
    color: "#94A3B8",
    fontSize:12,
    fontFamily:"Montserrat",
  },
  headerContainer: {
    display: "flex",
    height: "60px",
    padding: "10px 50px 10px 50px",
    alignItems: "center",
    background: "#ffffff",
    justifyContent:"space-between"
  },
  headerText: {
    fontFamily: "Montserrat",
    fontWeight: 700,
    fontSize: "22px",
    color: "#0074BF",
  },
  text: {
    fontFamily: "Montserrat",
    fontWeight: 600,
    fontSize: "15px",
    color: "#94A3B8",
    marginBottom:'5px'
  },
  text1: {
    fontFamily: "Montserrat",
    fontWeight: 400,
    fontSize: "13px",
    color: "#94A3B8",
    marginBottom:'55px',
    textAlign:'center'
  },
  headerText1: {
    fontFamily: "Montserrat",
    fontWeight: 700,
    fontSize: "14px",
    color: "#00AFEF",
    border:'2px solid #00AFEF',
    borderRadius:'20px',
    padding:'6px 20px 6px 20px',
    display:'flex',
    gap:'5px'
  },
  searchContainer:{ flexDirection: "row",
    alignItems: "center",
    padding:"0px 0px 0px 16px",
    display:"flex"
  },
  searchContainerForFilters:{ flexDirection: "row",
    alignItems: "center",
    display:"flex",
    margin:"0px 23px"
  },
  singleTestCategoryTouchable: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    margin:"10px 30px 15px 27px",
    display:"flex",
    alighnItems:"center",
    cursor:"pointer"
  },
  singleCheckList: {
    padding:"10px 27px",
    flexDirection: "row",
    alignItems: "center",
    display:"flex",
    cursor:"pointer"
  },
  singleCheckListTitle: {
    fontSize: 16,
    fontWeight: "400",
    color: "#0F172A",
    marginLeft: 19
  },
  searchPlaceholderFilters:{
    fontSize: 16,
    fontWeight: "400",
    marginLeft:"2px"
  },
  searchPlaceholder:{
    fontSize: 16,
    fontWeight: "400",
    marginLeft:"5px"
  },
  icons:{color:"#94A3B8", cursor:"pointer"},
  arrowStyle:{
    height: 12,
    width: 12,
    resizeMode: "contain",
    tintColor: "#94A3B8",
    cursor:"pointer"
  },
};

const CustomBoxSnackbar = styled(Box)<CustomBoxSnackbarProps>(({ alertType }) => ({
  color: alertType === "success" ? "#0F172A" : "red",
  display: "flex",
  alignItems: "center",
  padding: "8px",
  borderRadius: "4px",
  backgroundColor: alertType === "success" ? "#D1FAE5" : "#FEE2E2", // Example background color
}));

const styles = StyleSheet.create({
  arrowStyle:{
    height: 12,
    width: 12,
    resizeMode: "contain",
    tintColor: "#94A3B8",
  },
  filterScreen_title: {
    color: "#001C55",
    fontSize: 18,
    fontWeight: "700",
  },
  filterScreen_title1: {
    color: "#94A3B8",
    fontSize: 13,
    fontWeight: "700",
  },
  testCategoryTitle: {
    fontSize: 12,
    fontWeight: "700",
    color: "#94A3B8"
  },
  singleFilterBtn_left: {
    flexDirection: "row",
    justifyContent: "space-between",
    display:"flex"

  },
  singleFilterBtn_left_text: {
    marginLeft: 20
  },
  singleFilterBtn_left_helpText: {
    fontSize: 14,
    fontWeight: "500",
    color: "#94A3B8",
  },
  chipTitle: {
    fontSize: 14,
    fontWeight: "400",
    color: "#ffffff"
  },
  unSelectedChipTitle: {
    fontSize: 14,
    fontWeight: "400",
    color: "#001c55"
  },
  dateContainer :{
    width: "100%",
    borderBottomWidth: 1,
    borderBottomColor: "#E2E8F0",
  },
  timeSlots:{
    width: "100%",
    borderBottomWidth: 1,
    borderBottomColor: "#E2E8F0",
    paddingVertical:"3px",
  },
  settings:{
    flexDirection: "row",
    alignItems: "center",
    marginTop: 15,
    marginLeft:10
  },
  padding:{
    paddingVertical: 10,
    paddingHorizontal: 20,
  },
  checked:{
    width:20,
    height:20
  },
  selectAllTest:{
    paddingVertical: 10,
    paddingHorizontal: 12,
    flexDirection: "row",
    alignItems: "center",
  },
  subCate:{
    marginLeft: "27px",
    fontSize: 16,
    fontWeight: "400",
    color: "#0F172A",
  },
  editIcon:{color:"#0074BF"},
  eastIcon: {color:"#5274B5"},
  subContainer:{
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginHorizontal: 30,
    display:"flex"
  },
  subContainer2:{
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop:"20px",
    marginBottom:"20px",
    marginHorizontal: 30,
  },
  subContainer3:{
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    margin:"23px",
  },
  subContainerNotify:{
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginVertical: 13,
    marginRight:18,
    marginLeft:30
  },
  timePickerContainer:{ 
    flexDirection: "row", 
    alignItems: "center", 
    width: "220px" ,
  },
  flexContainer:{
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  iconColor:{ color: "#5274B5" },
  deleteIcon:{ color: "#F87171" },
  timeslotTypo:{
    fontSize: 16,
    color: "#0074BF",
    fontWeight: "500",
  },
  flexCenter:{ flexDirection: "row", alignItems: "center" },
  notifyAll:{
    fontSize: 16,
    color: "#475569",
    fontWeight: "500",
  },
  dialogTitle:{
    borderBottomWidth: 1,
    borderBottomColor: "#E2E8F0",
    fontSize: 22,
    fontWeight: "700",
    textAlign: 'left',
    color: "#001C55",
    fontFamily: "Montserrat",
    display: "flex",
    alignItems: "center",
    marginLeft:"5px"
  },
  closeIcon:{ width: "18px", height: "18px", color: "#0074BF" },
  borderBottom:{ 
    borderBottomWidth: 1,
    borderBottomColor: "#E2E8F0",
  },
  dialogContent:{ marginTop: 0, height: "inherit", paddingTop:10, paddingLeft:0, paddingRight:0,},
  leftArrow:{ marginRight: 25, marginLeft:8, height: '24px', width: '24px', color: "#0074BF" },
  detailsContainer:{
    height: 40,
    width: "100%",
    backgroundColor: "#5274B51A",
    justifyContent: "center",
  },
  detailsText:{
    fontSize: 14,
    fontWeight: "700",
    color: "#5274B5",
    lineHeight: 22,
    marginLeft: 30,
  },
  calenderContainer:{ 
    position: "relative" ,
    borderBottomWidth: 1,
    borderBottomColor: "#E2E8F0",
  },
  allCheck: {
    flexDirection: "row",
    alignItems: "center",
    marginVertical: 10,
    marginHorizontal:20
  },
  memberName:{ 
    marginLeft: 20,
    fontSize: 16,
    fontWeight: "400",
    color: "#0F172A",
  },
  imageStyle:{height:20, width:20},
  imageDeleteStyle:{height:40, width:40, marginRight:"20px"},
  deleteDialogContent:{ marginTop: 0, height: "inherit", padding:"0px"},
  imageProfileStyle: {
    width: 25,
    height: 25,
    borderRadius: 20,
    marginLeft:20
  },
  initialsContainer: {
    backgroundColor: "#5274B51A",
    justifyContent: "center",
    alignItems: "center",
    color:"#0074BF",
  },
  initialsText: {
    color: "#0074BF",
    fontSize: 12,
    fontWeight: "bold",
  },
  memberProfile:{height:20, width:20, borderRadius:50},
  selectText:{ 
    marginLeft: 20,
    fontSize: 16,
    fontWeight: "400",
    color: "#0F172A",
  },
 
  selectAllAirport:{
    flexDirection: "row",
    alignItems: "center",
    marginVertical: 10,
  },
  editText:{
    marginLeft: 22,
    fontSize: 16,
    fontWeight: "500",
    color: "#0074BF",
  },
  editAirport:{
    marginLeft: 23,
    fontSize: 16,
    fontWeight: "500",
    color: "#0074BF",
  },
  selectedZone:{
    marginLeft: 80,
    fontSize: 16,
    color: "#0F172A",
    fontWeight: "500",
    textAlign: "justify",
  },
  selectAirport:{
    paddingVertical: 10,
    paddingHorizontal: 32,
    borderBottomWidth: 1,
    borderBottomColor: "#E2E8F0",
  },
  allCheckImage:{
    height: 20,
    width: 20,
    resizeMode: "contain",
  },
  airportText:{
    marginLeft: "27px",
    fontSize: 16,
    fontWeight: "400",
    color: "#0F172A"
  },
  singleTest:{
    flexDirection: "row",
    alignItems: "center",
    marginVertical: 10,
    marginLeft:12
  }
})
// Customizable Area End
